import React from "react";
import {Link} from "gatsby";
import PageWrapper from "../../../components/PageWrapper";
import Hero from "../../../sections/campaigns/Hero";
import Main from "../../../sections/campaigns/Main";
import Features from "../../../sections/common/Features";
import VideoDemo from "../../../sections/common/VideoDemo";
import Wave from "../../../sections/common/Wave";
import WaveReverse from "../../../sections/common/WaveReverse";
import CTA from "../../../sections/CTA/CTA";
import SEO from '../../../components/SEO';
import metaImg from '../../../assets/image/metaimg/campaigns.jpg';
import { Helmet } from "react-helmet";

const PricingPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Interactive Video Campaigns"
          description="Creating the ability for potential online clientele to instantly interact with all video content makes DIGMA an absolutely essential device for any marketing strategy."
          image={metaImg}
        />    
        <Helmet>
        <link
          href={'https://cdn.digma.io/dist/digma.min.1.1.0.css?v=ecommerce'}
          rel="stylesheet"
          />
          <script async src={'https://cdn.digma.io/dist/digma.min.1.1.0.js?v=ecommerce'}></script>
        </Helmet>               
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries">Industries</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/ecommerce">E-commerce</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li> 
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/ecommerce/campaigns">Campaigns</Link>
              </li>                            
              </ol>
           </nav>
        </div>             
        <Hero />
        <WaveReverse pos="top" color="#0c426d" background="#fff" /> 
        <VideoDemo className="bg-digma-dark" name="Panthere De Cartier" projectId="7c196ad4-13cf-4b9a-af15-bd7c5cdc0dcd" />
        <Wave pos="bottom" color="#F7F9FC" background="#0c426d"  /> 
        <Main className="grey" />
        <Features />
        <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default PricingPage;
